const phoneFormat = {}

/* eslint-disable no-param-reassign */
function formatPhoneNumber(inputNumber) {
  // Remove non-digit characters from the input
  const cleanedNumber = inputNumber.replace(/\D/g, '')
  // Extract country code if present
  let countryCode = ''
  const countryCodeSize = cleanedNumber.length - 10
  countryCode = countryCodeSize !== 0 ? `+${cleanedNumber.slice(0, countryCodeSize)}` : ''

  // Format the remaining digits
  const formattedNumber = cleanedNumber.length > 3
    ? `${countryCode} (${cleanedNumber.slice(countryCodeSize, countryCodeSize + 3)}) ${cleanedNumber.slice(countryCodeSize + 3, countryCodeSize + 6)}-${cleanedNumber.slice(countryCodeSize + 6)}`
    : cleanedNumber

  return formattedNumber
}

/* eslint-enable no-param-reassign */
/* eslint-disable func-names */
phoneFormat.install = function (Vue) {
  Vue.filter('phone-format', val => formatPhoneNumber(val))
}
/* eslint-enable func-names */
export default phoneFormat
